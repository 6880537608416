<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=count"
    entity-name="区域"
    hide-query
    hide-show-detail
    hide-delete
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
    :requestConfig="{ noTempleFilter: true }"
    :action="action"
  >
    <template v-slot:edit="{ form }">
      <a-form-model-item label="区域名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入区域名称（最多10字）" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="关联区域" prop="real_name">
        <a-input
          v-model="form.real_name"
          placeholder="请输入关联区域（最多10字）"
          :maxLength="10"
          allowClear
          disabled
        ></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "NicheDistrict",
  data() {
    return {
      url: "/admin/mini-worship-district",
      columns: [
        { title: "区域名称", dataIndex: "name" },
        { title: "关联区域", dataIndex: "real_name" },
        { title: "区块数量", dataIndex: "count" },
      ],
      rules: {
        name: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
        real_name: [{ required: true, message: "请选择关联区域", trigger: "blur" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
