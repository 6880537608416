import { render, staticRenderFns } from "./NicheDistrict.vue?vue&type=template&id=2ade31fd&scoped=true&"
import script from "./NicheDistrict.vue?vue&type=script&lang=js&"
export * from "./NicheDistrict.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ade31fd",
  null
  
)

export default component.exports